#Feature0_0 .ant-col > .content0-block-item > .jzj8xt5kgv7-editor_css {
  font-size: 18px;
}
#Feature0_0 .ant-row > .ant-col > .content0-block-item {
}
#Feature0_0 .ant-col > .jzjgrrupf2c-editor_css > .content0-block-icon {
  width: 10px;
}
#Feature0_0 .ant-col > .jzjgrrupf2c-editor_css > .jzjgrlz134-editor_css {
  width: 190px;
  height: 190px;
}
#Feature0_0 .ant-col > .content0-block-item > .jzjgrlz134-editor_css {
  width: 190px;
}
#Feature0_0 .ant-col > .content0-block-item > .jzjncn210ql-editor_css {
  width: 190px;
  height: 190px;
}
#Feature0_0 .ant-col > .content0-block-item > .jzjndq0dueg-editor_css {
  width: 190px;
  height: 190px;
}
#Feature0_0 .ant-col > .content0-block-item > .jzjndsyw8sf-editor_css {
  width: 190px;
  height: 190px;
}
#Feature0_0 .ant-col > .content0-block-item > .jzjndw5oerk-editor_css {
  font-size: 18px;
}
#Feature0_0 .ant-col > .content0-block-item > .jzjne24af8c-editor_css {
  font-size: 18px;
}
#Feature0_0 .ant-col > .content0-block-item > .jzjne54fwqm-editor_css {
  font-size: 18px;
}
#Feature0_0 .ant-row > .ant-col > .jzjgrrupf2c-editor_css {
  padding: 0;
}

#Feature0_0 div > .ant-row > .content0-block {
  min-height: 340px;
  padding: 24px;
  transition: box-shadow 0.45s ease 0s;
}
#Feature0_0 div > .ant-row > .content0-block:hover {
  box-shadow: 0px 11px 56px rgba(100, 100, 136, 0.15);
}
#Nav3_0 .ant-menu-item > .header3-item-block > .k4xtgfl3ba-editor_css {
}
#Content13_0.k4xzti69nw-editor_css {
  background-image: url(https://imgconvert.csdnimg.cn/aHR0cHM6Ly9pbWcuc3ByaW5nbGVhcm4uY24vYmxvZy9sZWFybl8xNTc3NjM3OTA2MDAwLnBuZw?x-oss-process=image/format);
}
#Banner5_0 .home-page > .k4xt9h6tgp-editor_css {
}
#Feature1_0 .ant-row > .ant-col > .k4y04sjtaf9-editor_css {
  width: 100%;
  height: 100%;
  margin: 20vh 0 0;
}
#Feature2_1 .ant-row > .ant-col > .k4y0ewfbupu-editor_css {
  width: 100%;
  height: 100%;
  margin: 20vh 0 0;
}
#Nav3_0 .home-page > .k4xuc7j965t-editor_css {
  box-shadow: none;
}
